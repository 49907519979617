import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'

import { factumCircle, unverified, verified, wahooLogo } from 'assets/images'
import { ActionButton } from 'components'
import { BasicToast } from 'components/layout/basic-toast'
import { StackedToast } from 'components/layout/stacked-toast'
import { DEEP_LINK_BASE_URL } from 'config/env'

const WahooConnected = () => {
  const [searchParams] = useSearchParams()

  const { t } = useTranslation('auth')

  const { pathname } = useLocation()

  const isSuccess = useMemo(() => !searchParams.get('error'), [searchParams])

  const deepLinkUrl = useMemo(() => {
    if (!pathname) return null

    const deepLinkPath = pathname?.includes('onboarding')
      ? 'onboarding/wahoo-connected'
      : 'wahoo-connected'

    return `${DEEP_LINK_BASE_URL}${deepLinkPath}`
  }, [pathname])

  const openFactumApp = () => {
    location.replace(deepLinkUrl)
  }

  useEffect(() => {
    if (!document.body) return null

    document.body.classList.add('authentication-bg', isSuccess ? 'primary' : 'danger')

    return () =>
      document.body.classList.remove('authentication-bg', isSuccess ? 'primary' : 'danger')
  }, [isSuccess])

  return (
    <div className='account-pages pt-2 pt-sm-5 pb-4 pb-sm-5'>
      <Container>
        <Row className='justify-content-center'>
          <Col sm={9} md={8} lg={6} xl={5} xxl={4}>
            <Card className='gap-1'>
              <Card.Header className={`text-center bg-${isSuccess ? 'primary' : 'danger'}`}>
                <div className='logo-factum-background' />
              </Card.Header>
              <Card.Body className='p-3'>
                <div className='m-auto text-center d-flex flex-column align-items-center text-balance p-md-3'>
                  <div className='verified'>
                    <img
                      src={isSuccess ? '/verified.svg' : '/unverified.svg'}
                      onError={(event) => {
                        event.currentTarget.src = isSuccess ? verified : unverified
                      }}
                      alt='Status'
                    />
                  </div>
                  <div className='connect-strava'>
                    <div className='apps-wrapper border-primary-light'>
                      <img
                        src='/factum-circle.svg'
                        onError={(event) => {
                          event.currentTarget.src = factumCircle
                        }}
                        alt='factumlabs'
                      />
                    </div>
                    <div
                      className={classNames('chain-wrapper', {
                        success: isSuccess,
                        failed: !isSuccess,
                      })}
                    >
                      <i
                        className={classNames('icon icon-18px', {
                          'icon-chain-link text-primary-dark': isSuccess,
                          'icon-broken-chain-link text-danger': !isSuccess,
                        })}
                      />
                    </div>
                    <div className='apps-wrapper border-blue-light'>
                      <img
                        src='/wahoo-logo.svg'
                        onError={(event) => {
                          event.currentTarget.src = wahooLogo
                        }}
                        alt='wahoo'
                      />
                    </div>
                  </div>

                  <div className='my-3'>
                    <h4 className='text-black fw-semibold display-6'>
                      {isSuccess
                        ? t('Congrats! You’re Connected with Wahoo')
                        : t('Sorry, You’re Failed to Connect with Wahoo')}
                    </h4>
                    <p className='text-muted'>
                      {isSuccess
                        ? t('You have successfully connected your account with Wahoo.')
                        : t('Failed to connect your account with Wahoo.')}
                    </p>
                  </div>

                  <div className='w-100'>
                    <ActionButton
                      id='back-to-app'
                      className={classNames('m-auto font-16', { 'border-tertiary': !isSuccess })}
                      variant={isSuccess ? 'primary' : 'transparent'}
                      label={t('Back to Apps')}
                      onClick={openFactumApp}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <BasicToast />
      <StackedToast />
    </div>
  )
}

export default WahooConnected
