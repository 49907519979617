import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { leaderboardBackground, logoColorSm } from 'assets/images'
import { usePublicLeaderboard } from 'services/swr/use-public-leaderboard'
import { remoteImage } from 'utils/remote-image'

import { Avatar } from './components/avatar'
import './styles.scss'

const LeaderboardCard = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('auth')

  const [loadedImages, setLoadedImages] = useState<number[]>([])
  const [imagesLoaded, setImagesLoaded] = useState({ background: false, logo: false })

  const leaderboardParams = useMemo(
    () => ({
      type: searchParams.get('type') || 'distance',
      weekDate: searchParams.get('date') || new Date().toISOString(),
      dateType: searchParams.get('dateType') || 'weekly',
    }),
    [searchParams]
  )

  const { leaderboard } = usePublicLeaderboard({ id: params?.id, params: leaderboardParams })

  const valueKey = leaderboardParams.type === 'elevation' ? 'totalElevation' : 'totalDistance'

  const leaderboards = useMemo(() => {
    if (!leaderboard?.leaderboards) return []

    const sortedLeaderboards = leaderboard?.leaderboards
      .map((leaderboard) => ({ ...leaderboard }))
      .sort((a, b) => b[valueKey] - a[valueKey])
    let rank = 1

    for (let i = 0; i < sortedLeaderboards.length; i++) {
      if (i > 0 && sortedLeaderboards[i][valueKey] === sortedLeaderboards[i - 1][valueKey]) {
        sortedLeaderboards[i].rank = sortedLeaderboards[i - 1].rank
      } else {
        sortedLeaderboards[i].rank = rank
        rank++
      }
    }

    return sortedLeaderboards
  }, [leaderboard?.leaderboards, valueKey])

  const handleImageLoad = (id: number) => {
    setLoadedImages((prev) => [...prev, id])
  }

  useEffect(() => {
    const loadImages = (src: string, key: keyof typeof imagesLoaded) => {
      const img = new Image()
      img.src = src
      img.onload = () => setImagesLoaded((prev) => ({ ...prev, [key]: true }))
    }

    loadImages(leaderboardBackground, 'background')
    loadImages(logoColorSm, 'logo')

    return () => {
      setImagesLoaded({ background: false, logo: false })
    }
  }, [])

  const topRanks = useMemo(() => leaderboards.filter(({ rank }) => rank <= 3), [leaderboards])

  const allImagesLoaded = useMemo(() => {
    if (!topRanks.length || !loadedImages.length) return false

    return (
      topRanks.every(({ rank }) => loadedImages.includes(rank)) &&
      imagesLoaded.background &&
      imagesLoaded.logo
    )
  }, [loadedImages, topRanks, imagesLoaded])

  const renderRankComponent = (rank: number, avatarClass: string, medalClass: string) => {
    const userRank = topRanks.find(({ rank: r }) => r === rank)

    return (
      <div className={`${avatarClass}-wrapper`}>
        {userRank && (
          <>
            <Avatar
              src={remoteImage(userRank.user.photoProfile)}
              fallbackText={`${userRank.user.firstName} ${userRank.user.lastName}`}
              containerClassName={`${avatarClass}-avatar`}
              onRender={() => handleImageLoad(userRank.rank)}
            />
            <div className={`${medalClass}-medal`}>
              <h1 className='medal-text'>{rank}</h1>
            </div>
          </>
        )}
      </div>
    )
  }

  const renderTopRanks = useMemo(() => {
    if (!topRanks.length) return null

    return (
      <>
        {renderRankComponent(2, 'silver', 'silver')}
        {renderRankComponent(1, 'gold', 'gold')}
        {renderRankComponent(3, 'bronze', 'bronze')}
      </>
    )
  }, [topRanks])

  const challengeType =
    leaderboardParams.type === 'elevation' ? 'elevation challenge' : 'distance challenge'

  return (
    <div
      className='leaderboard-card'
      id={allImagesLoaded ? 'leaderboard-share-ready' : 'leaderboard-share'}
    >
      <div className='podium'>{renderTopRanks}</div>
      <div className='title-wrapper'>
        <div className='logo-image' />
        <h5 className='event-details-text'>
          {t('leaderboard_title', { type: challengeType, context: leaderboardParams.dateType })}
        </h5>
      </div>
    </div>
  )
}

export default LeaderboardCard
