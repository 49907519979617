import useSWR from 'swr'
import { derive } from 'valtio/utils'

import { modelAdaptor } from './middleware/model-adaptor'
import { GroupLeaderboard } from './models/group-leaderboard.types'

type UsePublicLeaderboar = {
  id: string
  params: any
}

export const usePublicLeaderboard = ({ id, params }: UsePublicLeaderboar) => {
  const { isLoading, error, mutate, data } = useSWR<GroupLeaderboard>(
    id && { path: 'public/leaderboards', id, params },
    {
      suspense: false,
      fetcher: async (args) => {
        let path = [args.path, args.id].filter(Boolean).join('/')
        const params = new URLSearchParams(args.params).toString()

        if (params) {
          path += `?${params}`
        }

        const data = await fetch(new URL(path, process.env.API_BASE_URL).toString())

        return data.json()
      },
      use: [modelAdaptor(() => derive({}), 'data')],
    }
  )

  return { leaderboard: data, isLoading, isError: !!error, mutate, error }
}
