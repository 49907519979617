export const logoLight = require('./logo-light.svg')
export const logoColor = require('./logo-color.svg')
export const logoColorPng = require('./logo-color.png')
export const logoLightSm = require('./logo-light-sm.svg')
export const logoColorSm = require('./logo-color-sm.svg')
export const logoRounded = require('./logo-rounded.svg')
export const dummyAvatar = require('./dummy-avatar.svg')
export const dummyExample = require('./email-campaign.svg')
export const bgImage = require('./bg-pattern-light.svg')
export const logoutIcon = require('./logout-icon.svg')
export const mailSent = require('./mail-sent.svg')
export const notFoundImg = require('./startman.svg')
export const deleteImage = require('./delete-image.svg')
export const banImage = require('./ban-image.svg')
export const deleteConfirmationImage = require('./delete-confirmation-image.svg')
export const universalLinkImage = require('./universal-link-image.svg')
export const playStoreBadge = require('./play-store-badge.svg')
export const appleStoreBadge = require('./apple-store-badge.svg')
export const documentImage = require('./document.svg')
export const routeImage = require('./route-image.svg')
export const groupImage = require('./group-image.svg')
export const settingImage = require('./setting-image.svg')
export const stravaIcon = require('./strava-icon.svg')
export const stravaLogo = require('./strava-logo.svg')
export const factumCircle = require('./factum-circle.svg')
export const stravaCircle = require('./strava-circle.svg')
export const verified = require('./verified.svg')
export const unverified = require('./unverified.svg')
export const placeholder = require('./placeholder.svg')
export const shareStory = require('./share-story.svg')
export const garminIcon = require('./garmin-icon.svg')
export const factumLogo = require('./factum-logo.svg')
export const arrowR = require('./arrow-r.svg')
export const eventBackground = require('./event-background.png')
export const leaderboardBackground = require('./leaderboard-background.png')
export const wahooLogo = require('./wahoo-logo.svg')
