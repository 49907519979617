import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { stravaIcon, stravaLogo } from 'assets/images'

type StravaIconProps = {
  variant?: 'circle' | 'square'
  className?: string
  show?: boolean
}

export const StravaIcon = (props: StravaIconProps) => {
  const { variant = 'circle', className = '', show } = props
  const { t } = useTranslation()

  if (!show) return <span>{t('None')}</span>

  return (
    <div
      className={classNames(
        'strava-icon-wrapper',
        {
          circle: variant === 'circle',
          square: variant === 'square',
        },
        className
      )}
    >
      <img src={stravaIcon} alt='Strava Icon' />
      {variant === 'square' && <img src={stravaLogo} alt='Strava Logo' />}
    </div>
  )
}
